body {
  margin: 0;
  font-family: 'Aleo', serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Aleo', serif !important;
  font-weight: bold;
}

.footerBackground {
  width: 100%;
  background: rgb(51, 125, 190);
  background: -moz-linear-gradient(
    180deg,
    rgba(51, 125, 190, 1) 0%,
    rgba(3, 92, 171, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(51, 125, 190, 1) 0%,
    rgba(3, 92, 171, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(51, 125, 190, 1) 0%,
    rgba(3, 92, 171, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#337dbe", endColorstr="#035cab", GradientType=1);
  padding: 20px;
  min-height: 105px;
  margin-top: 50px;
  position: relative;
}

.footerLabel {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  padding: 15px;
  border-bottom-right-radius: 20px;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
}

.impressumBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin: auto;
  font-size: 10px !important;
  text-align: center;
  text-transform: none;
}
.policyBottom {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin: auto;
  font-size: 10px !important;
  text-align: center;
  text-transform: none;
}

.footerLogo {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 15px;
}

/* tr.MuiTableRow-root td:first-child{
   padding:5px 0px!important;
   padding-right:10px!important;
} */

.nutritionTable {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}

/* .singleTable{
  padding:10px;
} */

.singleTable > div {
  /* border-bottom: 1px solid #bdbdbd; */
  padding: 10px;
}

.singleTable h6 {
  padding: 0 !important;
  font-size: 16px !important;
}
.singleTable .rowTitle {
  text-transform: capitalize !important;
}
/* .singleTable .rowTitleOfIngredients {
  text-transform: lowercase !important;
} */
.textWithUomNutritionalValue {
  text-transform: lowercase !important;
}
.nutritionTable .singleTable > div:nth-child(2),
.nutritionTable .singleTable > div:nth-child(4),
.nutritionTable .singleTable > div:last-child {
  border-bottom: 0;
}

.nutritionTable > div:nth-child(2) {
  /* border-left: 1px solid #ef7c3c; */
  flex: 0.3;
}

.nutritionTable > div:nth-child(1) {
  flex: 0.7;
}

.nutritionTable .singleTable > div:nth-child(3),
.nutritionTable .singleTable > div:nth-child(4),
.nutritionTable .singleTable > div:nth-child(7) {
  background-color: rgba(189, 189, 189, 0.2);
}
.nutritionTable .singleTable > div:nth-child(1) {
  border-bottom: 1px solid #bdbdbd;
}

.attributesTable .singleTable > div:last-child {
  border-bottom: 0;
}

.attributesTable {
  margin-bottom: 30px;
  /* border: 1px solid #ef7c3c; */
  border-radius: 5px;
}

.attributesTable .singleTable {
  display: 'flex';
  flex-wrap: wrap;
  flex-flow: row wrap;
}

.attributesTable .singleTable > div:nth-child(1),
.attributesTable .singleTable > div:nth-child(2),
.attributesTable .singleTable > div:nth-child(3),
.attributesTable .singleTable > div:nth-child(4),
.attributesTable .singleTable > div:nth-child(5),
.attributesTable .singleTable > div:nth-child(6) {
  max-width: 50%;
  padding-left: 0px;
}

/* .attributesTable .singleTable > div:nth-child(1),
.attributesTable .singleTable > div:nth-child(4),
.attributesTable .singleTable > div:nth-child(5),
.attributesTable .singleTable > div:nth-child(8) {
  background-color: rgba(239, 124, 60, 0.2);
}

.attributesTable .singleTable > div:nth-child(1),
.attributesTable .singleTable > div:nth-child(5) {
  border-right: 1px solid #ef7c3c;
}

.attributesTable .singleTable > div:nth-child(4) {
  border-left: 1px solid #ef7c3c;
} */

.wasteCard {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  height: 100%;
}

.smallMenuButton {
  width: 45px;
  height: 45px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 600px) {
  .attributesTable .singleTable > div:nth-child(1),
  .attributesTable .singleTable > div:nth-child(5) {
    border-right: 0;
  }

  .attributesTable .singleTable > div:nth-child(4) {
    border-left: 0;
  }

  .attributesTable .singleTable > div:nth-child(1),
  .attributesTable .singleTable > div:nth-child(2),
  .attributesTable .singleTable > div:nth-child(3),
  .attributesTable .singleTable > div:nth-child(4),
  .attributesTable .singleTable > div:nth-child(5),
  .attributesTable .singleTable > div:nth-child(6) {
    max-width: 100%;
  }

  .attributesTable .singleTable > div:nth-child(1),
  .attributesTable .singleTable > div:nth-child(4),
  .attributesTable .singleTable > div:nth-child(5),
  .attributesTable .singleTable > div:nth-child(8) {
    background-color: transparent;
  }

  .attributesTable .singleTable > div {
    background-color: white;
  }

  .attributesTable {
    margin-bottom: 30px;
    border: 0;
  }

  .attributesTable .singleTable > div {
    border-bottom: 1px solid #eaeaea;
    padding: 5px 0;
  }

  .footerBackground {
    min-height: 110px;
    margin-bottom: 130px;
  }
}
