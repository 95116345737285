.certificationsTable .MuiToolbar-root {
  display: block;
  padding: 0 !important;
}

.certificationsTable .MuiFormControl-root.MuiTextField-root {
  clear: both;
  width: 100%;
  margin: 0;
  padding: 0;
  margin: 15px 0;
}

.certificationsTable .MuiTableBody-root {
  padding: 10px !important;
}

.certificationsTable > .MuiPaper-root:last-child {
  padding: 10px;
}

.certificationsTable tr.MuiTableRow-root th:first-child,
.certificationsTable tr.MuiTableRow-root th:last-child,
.certificationsTable tr.MuiTableRow-root td:first-child,
.certificationsTable tr.MuiTableRow-root td:last-child {
  padding: 20px 5px !important;
  padding-left: 0 !important;
}

.certificationsTable tr.MuiTableRow-root th {
  font-weight: bold;
}

.certificationsTable tr.MuiTableRow-root td.MuiTablePagination-root {
  padding: 0 !important;
}

.certificationsTable h6.MuiTypography-root {
  margin-top: 0;
  padding-top: 0;
  text-transform: capitalize;
  font-family: 'Aleo', serif !important;
  font-weight: bold;
  display: none !important;
}

.certificationsTable.showTitle h6.MuiTypography-root {
  display: block !important;
  font-family: 'Aleo', serif !important;
  color: #3c4858;
}

.certificationsTable .MuiPaper-elevation2 {
  box-shadow: none !important;
}

.companyDescriptionContainer {
  -moz-column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-count: 2;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 20px;
  text-align: justify;
}
