body {
  background: #fafafa !important;
}

.presentationDescription {
  -moz-column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-count: 2;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 20px;
  margin: 30px 0;
}

.fredoka {
  font-family: 'Fredoka One', cursive;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.popover {
  background-color: #00a4ae;
  border-radius: 20px;
  position: relative;
  top: -10px;
  left: -10px;
  padding: 5px 10px;
  color: white !important;
  font-size: 12px;
}

.popover::before {
  content: '';
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #00a4ae;
  position: absolute;
  left: 45%;
  bottom: -10px;
  z-index: -1;
}

.customSelectFlagButton {
  background: #ef7c3c !important;
  color: #fafafa !important;
  border: 0px !important;
  /* font-family: 'Fredoka One' cursive !important; */
  margin-top: 20px;
  overflow: hidden;
}
