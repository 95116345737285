table td,
table th {
  font-family: 'Aleo', serif !important;
}

.certTable td {
  min-width: 200px;
  padding: 10px 5px !important;
}

.certTable tr.MuiTableRow-root td:first-child {
  padding: 10px 5px !important;
}
