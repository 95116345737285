.fullscreen {
  position: relative;
  z-index: 4;
}

.btnLink:hover {
  color: rgb(255, 255, 255) !important;
}

.btnLink,
.btnLink:link,
.btnLink:visited,
.btnLink:hover {
  color: rgb(255, 255, 255) !important;
}
a {
  color: #024289;
}

p {
  font-family: 'Aleo', serif;
  font-size: 1rem !important;
}

b {
  letter-spacing: 1px !important;
}

.legend {
  left: 0;
  top: 0;
  bottom: unset !important;
  width: 100%;
  border-radius: 0 !important;
  line-height: 120%;
  text-align: left !important;
  background-color: #09a963 !important;
}

.productImage {
  padding-top: 0px;
}

.thumb .productImage {
  padding-top: 0px;
}

ul.thumbs {
  margin: 0 !important;
}

.carousel .control-dots .dot {
  background: #000 !important;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #000 !important;
}
