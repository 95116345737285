:root {
  --smallW: 2vh;
  --smallH: 2vh;
  --mediumW: 4vh;
  --mediumH: 4vh;
  --largeW: 20vh;
  --largeH: 20vh;
  --dark: black;
  --light: white;
}

.small {
  width: var(--smallW);
  height: var(--smallH);
}

.medium {
  width: var(--mediumW);
  height: var(--mediumH);
}

.large {
  width: var(--largeW);
  height: var(--largeH);
}

.dark {
  color: var(--dark);
}

.light {
  color: var(--light);
}

.logoLoaderContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: white;
}

.logoLoaderContainer img {
  width: 10vw;
  height: 10vw;
  -webkit-transform-origin: 50% 80%;
  -webkit-transform: rotate(-15deg);
  transform-origin: 50% 80%;
  transform: rotate(-15deg);
  animation: splash-logo 1.5s ease-out infinite;
}

.descriptionSentences::before {
  content: url('../../assets/img/trusty-logo-black.svg');
  height: 30px;
  width: 30px;
  float: left;
  margin-top: -10px;
}

.descriptionSentences {
  text-align: left !important;
  padding-bottom: 30px;
}

@keyframes splash-logo {
  25% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(15deg);
    transform-origin: 50% 80%;
    transform: rotate(15deg);
  }
  45% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(-15deg);
    transform-origin: 50% 80%;
    transform: rotate(-15deg);
  }
  75% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(15deg);
    transform-origin: 50% 80%;
    transform: rotate(15deg);
  }
}

@media screen and (max-width: 600px) {
  .descriptionSentences {
    text-align: left !important;
    padding-bottom: 30px;
  }
}
